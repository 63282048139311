<!--
 * @Author: SongYijie
 * @Date: 2020-09-01 14:50:42
 * @LastEditors: SongYijie
-->
<template>
  <div>
    <router-view></router-view>
    <!--聊天悬浮窗 -->
    <Chat />
  </div>
</template>

<script>
import Chat from "@components/Chat";
export default {
  components: {
    Chat,
  },
  data() {
    return {};
  },
  computed: {},
  watch: {},
  methods: {},
};
</script>

<style lang='less' scoped>
</style>
