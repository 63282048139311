<!--
 * @Author: SongYijie
 * @Date: 2020-09-01 14:53:38
 * @LastEditors: SongYijie
-->
<!--  -->
<template>
  <div class="chat" @click="handleGotoConcat">

  </div>
</template>

<script>
import { getChatUrl } from '@api/user';
export default {
  data() {
    return {
    }
  },
  methods: {
    handleGotoConcat() {
      getChatUrl({}).then(res => {
        if (res.code === 200 && res.data) {
          window.location.href = res.data;
        }
      })
    }
  }
}
</script>
<style lang='less' scoped>
.chat {
  width: 10.1333vw;
  height: 10.1333vw;
  border-radius: 50%;
  background-color: #ef4f4f;
  position: fixed;
  right: 3vw;
  bottom: 30vw;
  z-index: 1000;
  background-image: url('./../assets/images/user/chat.png');
  background-size: 80% 80%;
  background-repeat: no-repeat;
  background-position: center center;
}
</style>
